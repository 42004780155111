import { Button, styled } from "@mui/material";

const Sc = {
  Button: styled(Button)({
    outline: "none",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    textTransform: "none",
  }),
};

export default Sc;
