import {
  categoriesPagePermissions,
  employeesPagePermissions,
  generalInfosPagePermissions,
  guestsPagePermissions,
  newTaskPagePermissions,
  notificationsPagePermissions,
  reportingPagePermissions,
  servicesPagePermissions,
  taskBoardPagePermissions,
  teamOperationsPagePermissions,
  teamsPagePermissions,
  zonesPagePermissions,
} from "~utils/app-roles";
import { voidFunction } from "~utils/functions";
import { forceNavigationToPath } from "~utils/navigation-utils";
import { Paths } from "~utils/paths";

import type { TMenuGroups } from "./menuList/type";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import ListIcon from "@mui/icons-material/List";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useRouter } from "next/router";

export const useGenerateMenuListItems = (): TMenuGroups[] => {
  const { pathname, push: navigateTo } = useRouter();

  const onClickTasksMenu = () => {
    if (pathname === Paths.Tasks) {
      forceNavigationToPath(Paths.Tasks);
    } else {
      navigateTo(Paths.Tasks).catch(voidFunction);
    }
  };

  return [
    {
      textKey: "sidebar.management",
      menuItems: [
        {
          icon: <ListIcon />,
          textKey: "sidebar.tasks",
          menuPath: Paths.Tasks,
          requiredUserPermissions: taskBoardPagePermissions.user,
          requiredHotelPermission: taskBoardPagePermissions.hotel,
          allUserPermissionsAreRequired: false,
          onClick: onClickTasksMenu,
        },
        {
          icon: <BarChartIcon />,
          textKey: "sidebar.reporting",
          menuPath: Paths.Reporting,
          requiredUserPermissions: reportingPagePermissions.user,
          requiredHotelPermission: reportingPagePermissions.hotel,
          allUserPermissionsAreRequired: false,
        },
        {
          icon: <GroupsIcon />,
          textKey: "sidebar.guests",
          menuPath: Paths.Guests,
          requiredUserPermissions: guestsPagePermissions.user,
          requiredHotelPermission: guestsPagePermissions.hotel,
          allUserPermissionsAreRequired: false,
        },
        {
          icon: <PlaylistAddIcon />,
          textKey: "sidebar.create",
          menuPath: Paths.NewTask,
          requiredUserPermissions: newTaskPagePermissions.user,
          requiredHotelPermission: newTaskPagePermissions.hotel,
          allUserPermissionsAreRequired: false,
        },
      ],
    },
    {
      textKey: "sidebar.settings",
      menuItems: [
        {
          icon: <StorefrontIcon />,
          textKey: "sidebar.establishment",
          menuPath: Paths.GeneralInfos,
          requiredUserPermissions: generalInfosPagePermissions.user,
          requiredHotelPermission: generalInfosPagePermissions.hotel,
          allUserPermissionsAreRequired: false,
        },

        {
          icon: <AssignmentOutlinedIcon />,
          textKey: "sidebar.services",
          menuPath: Paths.Services,
          requiredUserPermissions: servicesPagePermissions.user,
          requiredHotelPermission: servicesPagePermissions.hotel,
          allUserPermissionsAreRequired: false,
        },
        {
          icon: <GroupIcon />,
          textKey: "sidebar.teams",
          menuPath: Paths.Teams,
          requiredUserPermissions: teamsPagePermissions.user,
          requiredHotelPermission: teamsPagePermissions.hotel,
          allUserPermissionsAreRequired: false,
        },
        {
          icon: <ManageAccountsIcon />,
          textKey: "sidebar.employees",
          menuPath: Paths.Employees,
          requiredUserPermissions: employeesPagePermissions.user,
          requiredHotelPermission: employeesPagePermissions.hotel,
          allUserPermissionsAreRequired: false,
        },
        {
          icon: <SettingsSuggestIcon />,
          textKey: "sidebar.team_operations",
          menuPath: Paths.TeamOperations,
          requiredUserPermissions: teamOperationsPagePermissions.user,
          requiredHotelPermission: teamOperationsPagePermissions.hotel,
          allUserPermissionsAreRequired: false,
        },
        {
          icon: <NotificationsActiveIcon />,
          textKey: "sidebar.notifications",
          menuPath: Paths.Notifications,
          requiredUserPermissions: notificationsPagePermissions.user,
          requiredHotelPermission: notificationsPagePermissions.hotel,
          allUserPermissionsAreRequired: true,
        },
        {
          icon: <ShareLocationIcon />,
          textKey: "sidebar.zones",
          menuPath: Paths.Zones,
          requiredUserPermissions: zonesPagePermissions.user,
          requiredHotelPermission: zonesPagePermissions.hotel,
          allUserPermissionsAreRequired: true,
        },
        {
          icon: <LocalOfferIcon />,
          textKey: "sidebar.categories",
          menuPath: Paths.Categories,
          requiredUserPermissions: categoriesPagePermissions.user,
          requiredHotelPermission: categoriesPagePermissions.hotel,
          allUserPermissionsAreRequired: true,
        },
      ],
    },
  ];
};
