import { Row } from "../../flexs/Row";
import { Body2 } from "../../typography/Body2";

import { styled } from "@mui/material";
const Sc = {
  Container: styled(Row)({
    alignItems: "center",
    columnGap: "10px",
  }),
  Text: styled(Body2)``,
};
export default Sc;
