import { useSelectCurrentUser } from "~store/current-user/selector";
import { isNullOrUndefined } from "~utils/boolean-utils";
import { useUserScreen } from "~utils/responsive/hooks";

import LogoButton from "../logoButton";

import MenuList from "./menuList/MenuList";
import { useGenerateMenuListItems } from "./constants";
import { Sc } from "./SideBar.style";

import type { FC } from "react";

interface Props {
  isOpen: boolean;
}

const SideBar: FC<Props> = ({ isOpen }) => {
  const { userHasSmallScreen } = useUserScreen();
  const menuGroups = useGenerateMenuListItems();
  const currentUser = useSelectCurrentUser();
  if (isNullOrUndefined(currentUser)) {
    return null;
  }

  return (
    <Sc.StyledDrawer variant={userHasSmallScreen ? "persistent" : "permanent"} open={isOpen}>
      {!userHasSmallScreen && <LogoButton />}
      <MenuList menuGroups={menuGroups} />
    </Sc.StyledDrawer>
  );
};

export default SideBar;
