import { styled } from "@mui/material";

export const StyledMenuList = styled("div")({
  marginInline: 16,
  marginTop: 24,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "50px",
});
