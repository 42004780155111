import { Body1 } from "~src/common/components/typography/Body1";

import CustomAvatar from "./customAvatar/CustomAvatar";
import Sc from "./ProfileBadge.style";

import { useTheme } from "@mui/material";
import type { FC } from "react";

interface IProfileBadgeProps {
  title: string;
  subTitle: string;
}

const ProfileBadge: FC<IProfileBadgeProps> = ({ title, subTitle }) => {
  const theme = useTheme();

  const getInitials = (name: string) => {
    return name
      .toUpperCase()
      .split(" ")
      .filter((_, index) => index < 2)
      .map((word) => word[0])
      .join("");
  };

  return (
    <Sc.StyledRow>
      <CustomAvatar profileText={getInitials(title)} />
      <Sc.StyledColumn>
        <Body1 value={title} weight="medium" color={theme.palette.primary[900]} />
        <Body1 value={subTitle} color={theme.palette.primary[500]} />
      </Sc.StyledColumn>
    </Sc.StyledRow>
  );
};

export default ProfileBadge;
