import { useLogout } from "~api/auth/hooks";
import { useTranslate } from "~i18n/hooks";
import { useGlobalState } from "~store";
import { useOpenSnackBar } from "~store/snack-bar/hooks";
import { voidFunction } from "~utils/functions";
import { Paths } from "~utils/paths";
import type { Callback, Consumer, TSubmitDescriptor } from "~utils/types";

import { useRouter } from "next/router";

export const useSubmitLogout = (): TSubmitDescriptor<void> => {
  const { resetGlobalState } = useGlobalState();
  const { openSnackBar } = useOpenSnackBar();
  const translate = useTranslate();
  const { push: navigateTo } = useRouter();

  const redirectToLoginPage = () => {
    navigateTo(Paths.Login).catch(voidFunction);
  };

  const onLogoutSuccess: Callback = () => {
    resetGlobalState();
    redirectToLoginPage();
    openSnackBar({ message: translate("logout.successMessage"), severity: "success" });
  };

  const onLoginError: Consumer<unknown> = () => {
    openSnackBar({ message: translate("common.errors.logout"), severity: "error" });
  };

  const { mutate, ...queryResultProps } = useLogout(onLogoutSuccess, onLoginError);
  return {
    ...queryResultProps,
    onSubmit: () => {
      mutate();
    },
  };
};
