import { useEffect, useState } from "react";

import { Column } from "~src/common/components/flexs/Column";
import { useCurrentUser } from "~store/current-user/hooks";
import { useUserScreen } from "~utils/responsive/hooks";

import CustomMenuItem from "../customMenuItem";
import type { TMenuListItem } from "../type";

import { Sc } from "./CustomMultipleOptionsMenuItem.style";

import { useRouter } from "next/router";
import type { FC } from "react";

type TMenuItemProps = TMenuListItem;

const CustomMultipleOptionsMenuItem: FC<TMenuItemProps> = ({ icon, menuPath, textKey, nestedMenus = [] }) => {
  const { userHasSmallScreen } = useUserScreen();
  const { pathname: currentPath } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { userHasAnyOfThesePermissions, userHasAllOfThesePermissions, userHotelHasAnyOfThesePermission } =
    useCurrentUser();

  const menuItemNotAccessible = (menuItemProps: TMenuListItem): boolean => {
    return (
      !userHotelHasAnyOfThesePermission(menuItemProps.requiredHotelPermission) ||
      (menuItemProps.allUserPermissionsAreRequired &&
        !userHasAllOfThesePermissions(menuItemProps.requiredUserPermissions)) ||
      (!menuItemProps.allUserPermissionsAreRequired &&
        !userHasAnyOfThesePermissions(menuItemProps.requiredUserPermissions))
    );
  };

  useEffect(() => {
    setIsOpen(nestedMenus.some((menu) => menu.menuPath === currentPath));
  }, [currentPath, nestedMenus]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  if (userHasSmallScreen) {
    return (
      <Column rowGap={5}>
        {nestedMenus.map((menuProps) =>
          menuItemNotAccessible(menuProps) ? null : <CustomMenuItem key={menuProps.menuPath} {...menuProps} />,
        )}
      </Column>
    );
  }

  return (
    <Sc.Accordion expanded={isOpen}>
      <Sc.AccordionHeader>
        <CustomMenuItem icon={icon} textKey={textKey} menuPath={menuPath} onClick={toggleAccordion} />
      </Sc.AccordionHeader>
      <Sc.AccordionBody>
        <Column rowGap={5}>
          {nestedMenus.map((menuProps) =>
            menuItemNotAccessible(menuProps) ? null : <CustomMenuItem key={menuProps.menuPath} {...menuProps} />,
          )}
        </Column>
      </Sc.AccordionBody>
    </Sc.Accordion>
  );
};

export default CustomMultipleOptionsMenuItem;
