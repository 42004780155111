import theme from "~providers/muiThemeProvider/mui-theme";

import { styled } from "@mui/material";

export const Scrollable = styled("div")(() => ({
  overflowY: "scroll",
  height: "100%",
  scrollbarGutter: "5px",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary[100],
    borderRadius: "12px",
    border: `1px solid ${theme.palette.background.paper}`,
    boxShadow: `0px 1px 3px ${theme.palette.background.paper}`,
  },
  "--mask-image-content": `linear-gradient(to top, transparent, black var(--mask-height), black calc(100% - var(--mask-height)), transparent)`,
  "--mask-size-content": `calc(100% - var(--scrollbar-width)) 100%`,
  "--mask-image-scrollbar": "linear-gradient(white, white)",
  "--mask-size-scrollbar": "var(--scrollbar-width) 100%",
  maskImage: "var(--mask-image-content), var(--mask-image-scrollbar)",
  maskSize: "var(--mask-size-content), var(--mask-size-scrollbar)",
  maskPosition: "0 0, 100% 0",
  maskRepeat: "no-repeat, no-repeat",
}));
