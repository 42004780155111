import { voidFunction } from "~utils/functions";

import Sc from "./ButtonContainer.style";

import type { FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  onClick?: () => void;
}
export const ButtonContainer: FC<Props> = ({ onClick = voidFunction, children }) => {
  return <Sc.Button onClick={onClick}>{children}</Sc.Button>;
};
