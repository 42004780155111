import { NavBarMaxHeight, SideBarMaxWidth } from "~styles/constants";
import { sizes } from "~utils/responsive/breakPointsResponsive";

import { Drawer, styled } from "@mui/material";

export const Sc = {
  StyledDrawer: styled(Drawer)({
    maxWidth: SideBarMaxWidth,
    height: "100%",
    overflow: "hidden",
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      position: "relative",
      boxShadow: "5px 0 5px -5px rgba(0, 0, 0, 0.25)",

      [`@media (max-width: ${sizes.sm})`]: {
        height: `calc(100% - ${NavBarMaxHeight})`,
        marginTop: NavBarMaxHeight,
        position: "absolute !important",
      },
    },
  }),
};
