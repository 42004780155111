import { useTranslate } from "~i18n/hooks";
import type { TxKeyPath } from "~i18n/types";
import { isDefined } from "~utils/boolean-utils";
import { voidFunction } from "~utils/functions";
import type { Callback } from "~utils/types";

import { Sc } from "./CustomMenuItem.style";

import { useRouter } from "next/router";
import type { FC, ReactElement } from "react";

interface IMenuItemProps {
  icon: ReactElement;
  textKey: TxKeyPath;
  menuPath: string;
  onClick?: Callback;
}

const CustomMenuItem: FC<IMenuItemProps> = ({ icon, menuPath, textKey, onClick = undefined }) => {
  const { push: routerPush, pathname: currentPath } = useRouter();
  const translate = useTranslate();

  const navigateToMenuPath = async () => {
    await routerPush(menuPath).catch(voidFunction);
  };

  const onMenuClick = isDefined(onClick) ? onClick : navigateToMenuPath;

  return (
    <Sc.MenuItemContainer onClick={onMenuClick}>
      <Sc.MenuItem selected={currentPath === menuPath}>
        <Sc.ListItemIcon>{icon}</Sc.ListItemIcon>
        <Sc.Text value={translate(textKey)} weight="regular" />
      </Sc.MenuItem>
    </Sc.MenuItemContainer>
  );
};

export default CustomMenuItem;
