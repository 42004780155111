import theme from "~providers/muiThemeProvider/mui-theme";
import { Row } from "~src/common/components/flexs/Row";
import { NavBarMaxHeight } from "~styles/constants";
import { sizes } from "~utils/responsive/breakPointsResponsive";

import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const IconStyle = {
  color: theme.palette.primary[500],
  fontSize: "40px",
  display: "none",
  cursor: "pointer",

  [`@media (max-width: ${sizes.sm})`]: {
    display: "block",
  },
};

const Sc = {
  Row: styled(Row)({
    maxHeight: NavBarMaxHeight,
    padding: "15px 20px",
  }),
  MenuIcon: styled(MenuIcon)(IconStyle),
  CloseIcon: styled(CloseIcon)(IconStyle),
};

export default Sc;
