import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material";

export const Sc = {
  Accordion: styled(Accordion)`
    box-shadow: none;
    position: static;
    margin-top: -1px !important;
    &.Mui-expanded {
      margin: 0 !important;
    }
  `,
  AccordionHeader: styled(AccordionSummary)`
    display: inline !important;
    padding: 0;
    & .Mui-expanded {
      margin-top: 0 !important;
    }
    & .MuiAccordionSummary-content {
      display: block !important;
      margin: 0px !important;
    }
  `,
  AccordionBody: styled(AccordionDetails)``,
};
