import Sc from "./IconWithLabel.style";

import type { FC, ReactElement } from "react";

interface IIconWithLabelProps {
  icon: ReactElement;
  label: string;
}

export const IconWithLabel: FC<IIconWithLabelProps> = ({ icon, label }) => {
  return (
    <Sc.Container>
      {icon}
      <Sc.Text value={label} />
    </Sc.Container>
  );
};
