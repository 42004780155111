import { useGetCurrentUser } from "~store/current-user/hooks";
import { isNullOrUndefined } from "~utils/boolean-utils";
import { useUserScreen } from "~utils/responsive/hooks";
import type { Consumer } from "~utils/types";

import LogoButton from "../logoButton";

import ProfileBadge from "./profileBadge/ProfileBadge";
import Sc from "./NavBar.style";

import type { FC } from "react";

interface Props {
  isSideBarOpened: boolean;
  setIsSideBarOpened: Consumer<boolean>;
}
const NavBar: FC<Props> = ({ isSideBarOpened, setIsSideBarOpened }) => {
  const { userHasSmallScreen } = useUserScreen();
  const { user } = useGetCurrentUser();
  if (isNullOrUndefined(user)) return null;

  const { name, role } = user;
  const roleName = role.name;

  const toggleSideBar = () => {
    setIsSideBarOpened(!isSideBarOpened);
  };

  return (
    <Sc.Row justify={userHasSmallScreen ? "space-between" : "flex-end"}>
      {isSideBarOpened ? <Sc.CloseIcon onClick={toggleSideBar} /> : <Sc.MenuIcon onClick={toggleSideBar} />}
      {userHasSmallScreen && <LogoButton />}
      <ProfileBadge title={name} subTitle={roleName} />
    </Sc.Row>
  );
};

export default NavBar;
