import { useEffect, useState } from "react";

import { SITE_CONFIG } from "~config/site";
import { ImageViewer } from "~src/common/components/imageViewer/ImageViewer";

import NavBar from "./components/navBar";
import SideBar from "./components/sideBar";
import Sc from "./DefaultLayout.style";

import Head from "next/head";
import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import type { FC, PropsWithChildren } from "react";

const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const [isSideBarOpened, setIsSideBarOpened] = useState<boolean>(false);

  useEffect(() => {
    if (isSideBarOpened) {
      setIsSideBarOpened(false);
    }
  }, [router.pathname]);

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      </Head>

      <DefaultSeo
        canonical={SITE_CONFIG.siteUrl + router.asPath}
        defaultTitle={SITE_CONFIG.title}
        description={SITE_CONFIG.description}
        titleTemplate={`%s ${SITE_CONFIG.titleSeparator} ${SITE_CONFIG.title}`}
      />

      <Sc.MainContainer>
        <ImageViewer />
        <Sc.ContentContainer>
          <SideBar isOpen={isSideBarOpened} />
          <Sc.PageContainer>
            <NavBar isSideBarOpened={isSideBarOpened} setIsSideBarOpened={setIsSideBarOpened} />
            {children}
          </Sc.PageContainer>
        </Sc.ContentContainer>
      </Sc.MainContainer>
    </>
  );
};

export default DefaultLayout;
