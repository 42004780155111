import { sizes } from "./breakPointsResponsive";

import { useMediaQuery } from "@mui/material";

interface ScreenType {
  userHasSmallScreen: boolean;
  userHasMediumScreen: boolean;
  userHasBigScreen: boolean;
}

export const useUserScreen = (): ScreenType => {
  const userHasSmallScreen = useMediaQuery(`(max-width:${sizes.sm})`);
  const userHasMediumScreen = useMediaQuery(`(min-width:${sizes.sm})`);
  const userHasBigScreen = useMediaQuery(`(min-width:${sizes.lg})`);

  return {
    userHasSmallScreen,
    userHasMediumScreen,
    userHasBigScreen,
  };
};
