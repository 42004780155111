import theme from "~providers/muiThemeProvider/mui-theme";
import { Column } from "~src/common/components/flexs/Column";
import { Row } from "~src/common/components/flexs/Row";
import { Scrollable } from "~src/common/components/flexs/Scrollable";

import { styled } from "@mui/material";

const Sc = {
  MainContainer: styled(Column)({
    height: "100vh",
  }),

  ContentContainer: styled(Row)({
    backgroundColor: theme.palette.background.default,
    height: `100%`,
  }),

  PageContainer: styled(Scrollable)({
    flex: 1,
  }),
};

export default Sc;
