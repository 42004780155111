import { Column } from "~src/common/components/flexs/Column";
import { Row } from "~src/common/components/flexs/Row";
import { sizes } from "~utils/responsive/breakPointsResponsive";

import { styled } from "@mui/material";

const Sc = {
  StyledRow: styled(Row)({
    height: "100%",
    alignItems: "center",
    gap: "10px",
  }),
  StyledColumn: styled(Column)({
    height: "100%",
    width: "100%",
    gap: "2px",

    [`@media (max-width: ${sizes.sm})`]: {
      display: "none",
    },
  }),
};

export default Sc;
