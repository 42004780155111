import { useTranslate } from "~i18n/hooks";
import { Column } from "~src/common/components/flexs/Column";
import { Body2 } from "~src/common/components/typography/Body2";
import { useCurrentUser } from "~store/current-user/hooks";
import { isDefined } from "~utils/boolean-utils";

import Logout from "../logout";

import CustomMultipleOptionsMenuItem from "./customMultipleOptionsMenuItem/CustomMultipleOptionsMenuItem";
import CustomMenuItem from "./customMenuItem";
import { StyledMenuList } from "./MenuList.style";
import type { TMenuGroups, TMenuListItem } from "./type";

import type { FC } from "react";

interface IMenuListProps {
  menuGroups: TMenuGroups[];
}

const MenuList: FC<IMenuListProps> = ({ menuGroups }) => {
  const translate = useTranslate();
  const { userHasAnyOfThesePermissions, userHasAllOfThesePermissions, userHotelHasAnyOfThesePermission } =
    useCurrentUser();

  const hasAccessToMenuItem = (menuItemProps: TMenuListItem): boolean => {
    return !(
      !userHotelHasAnyOfThesePermission(menuItemProps.requiredHotelPermission) ||
      (menuItemProps.allUserPermissionsAreRequired &&
        !userHasAllOfThesePermissions(menuItemProps.requiredUserPermissions)) ||
      (!menuItemProps.allUserPermissionsAreRequired &&
        !userHasAnyOfThesePermissions(menuItemProps.requiredUserPermissions))
    );
  };

  const hasAccessToMenuGroup = (menuGroup: TMenuGroups) => {
    return menuGroup.menuItems.some((menuItemProps) => {
      return hasAccessToMenuItem(menuItemProps);
    });
  };

  return (
    <Column rowGap={50}>
      <StyledMenuList>
        {menuGroups.map((menuGroup) =>
          hasAccessToMenuGroup(menuGroup) ? (
            <Column key={menuGroup.textKey}>
              <Body2 color="primary" value={translate(menuGroup.textKey)} />
              {menuGroup.menuItems.map((menuItemProps) => {
                if (!hasAccessToMenuItem(menuItemProps)) {
                  return null;
                }
                if (isDefined(menuItemProps.nestedMenus) && menuItemProps.nestedMenus.length > 0) {
                  return <CustomMultipleOptionsMenuItem key={menuItemProps.menuPath} {...menuItemProps} />;
                }

                return <CustomMenuItem key={menuItemProps.menuPath} {...menuItemProps} />;
              })}
            </Column>
          ) : null,
        )}
      </StyledMenuList>
      <Logout />
    </Column>
  );
};

export default MenuList;
