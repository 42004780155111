import type { HotelPermissions, UserPermissions } from "./types/global-types";

export type AllowedPermissions = {
  hotel: HotelPermissions[];
  user: UserPermissions[];
};

export const reportingPagePermissions: AllowedPermissions = {
  hotel: ["see_reporting"],
  user: ["see_operations_perfomance_reporting", "see_clients_reporting", "see_incidents_reporting"],
};

export const servicesPagePermissions: AllowedPermissions = {
  hotel: ["see_configuration_services"],
  user: ["see_services"],
};
export const taskBoardPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["list_all_tasks", "list_team_tasks", "list_own_tasks"],
};

export const taskDetailsPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["list_all_tasks", "list_team_tasks", "list_own_tasks"],
};

export const createIncidentPagePermissions: AllowedPermissions = {
  hotel: ["see_create_incident"],
  user: ["create_incident"],
};

export const createGuestNeedPagePermissions: AllowedPermissions = {
  hotel: ["see_create_guest_need"],
  user: ["create_guest_need", "list_all_guests"],
};

export const guestsPagePermissions: AllowedPermissions = {
  hotel: ["see_guests"],
  user: ["list_all_guests"],
};
export const guestDetailsPagePermissions: AllowedPermissions = {
  hotel: ["see_guests"],
  user: ["list_all_guests"],
};

export const guestStaysPagePermissions: AllowedPermissions = {
  hotel: ["see_guests"],
  user: ["list_all_guests"],
};

export const guestNeedsPagePermissions: AllowedPermissions = {
  hotel: ["see_guests"],
  user: ["list_all_guests"],
};

export const guestNeedTasksPagePermissions: AllowedPermissions = {
  hotel: ["see_guests"],
  user: ["list_all_guests"],
};

export const guestConversationsPagePermissions: AllowedPermissions = {
  hotel: ["see_guests"],
  user: ["list_all_guests"],
};

export const createGuestPagePermissions: AllowedPermissions = {
  hotel: ["see_guests"],
  user: ["create_guest"],
};

export const importGuestsPagePermissions: AllowedPermissions = {
  hotel: ["see_guests"],
  user: ["create_guest"],
};

export const newPasswordPagePermissions: AllowedPermissions = {
  hotel: [],
  user: [],
};

export const generalInfosPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["edit_establishment"],
};

export const employeesPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["see_employees"],
};

export const notificationsPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["see_notifications"],
};

export const zonesPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["see_locations"],
};

export const categoriesPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["see_locations"],
};

export const teamsPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["see_teams"],
};

export const teamOperationsPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["see_operations"],
};

export const faqsPagePermissions: AllowedPermissions = {
  hotel: [],
  user: ["see_faqs"],
};

export const newTaskPagePermissions: AllowedPermissions = {
  hotel: ["create_incident", "create_booking", "create_internal_task", "create_internal_task", "create_order"],
  user: ["create_incident", "create_booking", "create_internal_task", "create_internal_task", "create_order"],
};
