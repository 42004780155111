import { StyledAvatar } from "./CustomAvatar.style";

import type { FC } from "react";

interface ICustomAvatarProps {
  profileText: string;
}

const CustomAvatar: FC<ICustomAvatarProps> = ({ profileText }) => {
  return <StyledAvatar>{profileText}</StyledAvatar>;
};

export default CustomAvatar;
