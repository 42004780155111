import theme from "~providers/muiThemeProvider/mui-theme";
import { Column } from "~src/common/components/flexs/Column";

import { styled } from "@mui/material";

const Sc = {
  Column: styled(Column)({
    padding: "6px 16px",

    borderRadius: 8,
    backgroundColor: theme.palette.primary[100],
    cursor: "pointer",
    marginInline: 16,
    marginBottom: 20,
  }),
};

export default Sc;
