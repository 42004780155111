import { useTranslate } from "~i18n/hooks";
import IconWithLabel from "~src/common/components/labeled/iconWithLabel";

import Sc from "./Logout.style";
import { useSubmitLogout } from "./useSubmitLogout";

import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import type { FC } from "react";

const Logout: FC = () => {
  const { onSubmit } = useSubmitLogout();
  const translate = useTranslate();
  const logoutIcon = <PowerSettingsNewIcon color="error" />;

  return (
    <Sc.Column
      onClick={() => {
        onSubmit();
      }}
    >
      <IconWithLabel label={translate("logout.confirmButton")} icon={logoutIcon}></IconWithLabel>
    </Sc.Column>
  );
};

export default Logout;
