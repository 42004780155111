import theme from "~providers/muiThemeProvider/mui-theme";
import { Body1 } from "~src/common/components/typography/Body1";
import { mapTWeitghToFontWeight } from "~src/common/components/typography/mappers";

import { Accordion, AccordionDetails, AccordionSummary, ListItemIcon, MenuItem, styled } from "@mui/material";

export const Sc = {
  MenuItemContainer: styled("div")({}),
  Text: styled(Body1)`
    cursor: pointer !important;
  `,
  MenuItem: styled(MenuItem)({
    borderRadius: 8,
    padding: "6px 16px",
    color: theme.palette.grey[900],
    "& .MuiListItemIcon-root": {
      color: theme.palette.grey[900],
    },
    height: "100%",
    "&.Mui-selected": {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.primary[500],
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary[500],
      },
      "& .MuiTypography-root ": {
        fontWeight: mapTWeitghToFontWeight("medium"),
      },
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.primary[500],
    },
  }),
  ListItemIcon: styled(ListItemIcon)({}),

  Accordion: styled(Accordion)`
    box-shadow: none;

    &.Mui-expanded {
      margin: 0 !important;
    }
    .Mui-expanded {
      margin: 0 !important;
    }
  `,
  AccordionHeader: styled(AccordionSummary)`
    padding: 0;
    min-height: 35px !important;
    &.Mui-expanded {
      margin: 0 !important;
    }
  `,
  AccordionBody: styled(AccordionDetails)``,
};
