import Logo from "~src/assets/icons/logo";
import ButtonContainer from "~src/common/components/buttons/ButtonContainer";
import { forceNavigationToPath } from "~utils/navigation-utils";
import { Paths } from "~utils/paths";

import type { FC } from "react";

export const LogoButton: FC = () => {
  const onClickLogo = () => {
    forceNavigationToPath(Paths.Tasks);
  };

  return (
    <ButtonContainer onClick={onClickLogo}>
      <Logo />
    </ButtonContainer>
  );
};
