import { useAppDispatch } from "~store/hooks";

import { useImageViewerSelector } from "./selectors";
import { resetState, setImages, setIsOpen, setStartIndex } from "./slice";
import type { ImageViewState, IUseImageViewer } from "./types";

export const useImageViewer = (): IUseImageViewer => {
  const dispatch = useAppDispatch();

  const setIsOpenFn = (open: boolean) => {
    dispatch(setIsOpen(open));
  };

  const openImageViewerFn = (imageUrls: string[], startIndex: number) => {
    dispatch(setIsOpen(true));
    dispatch(setImages(imageUrls));
    dispatch(setStartIndex(startIndex));
  };

  const updateImagesFn = (imageUrls: string[]) => {
    dispatch(setImages(imageUrls));
  };

  const resetStateFn = () => {
    dispatch(resetState());
  };

  return {
    openImageViewer: openImageViewerFn,
    setIsOpen: setIsOpenFn,
    updateImages: updateImagesFn,
    resetImageViewer: resetStateFn,
  };
};

export const useGetImageViewer = (): Readonly<ImageViewState> => {
  const data = useImageViewerSelector();

  return data;
};
