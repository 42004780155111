import "react-awesome-lightbox/build/style.css";

import { useGetImageViewer, useImageViewer } from "~store/image-viewer/hooks";
import { isEmpty } from "~utils/boolean-utils";

import type { FC } from "react";
import Lightbox from "react-awesome-lightbox";

export const ImageViewer: FC = () => {
  const { images, startIndex, isOpen } = useGetImageViewer();
  const { resetImageViewer } = useImageViewer();

  if (!isOpen || isEmpty(images ?? [])) {
    return null;
  }

  return <Lightbox images={images} startIndex={startIndex} onClose={resetImageViewer} />;
};
